<style scoped>
.user-panel {
	margin: 10px auto;
}
</style>
<template>
	<el-dialog
		:visible.sync="showDialog"
		:title="title"
		:modal-append-to-body="false"
		style="text-align: left"
		@close="closeDialog"
		:close-on-click-modal="false"
	>
		<div class="user-panel" v-loading="loading">
			<el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'180px'">
				<el-form-item label="车牌号码" prop="memberCarNum">
					<el-input v-model="formModel.memberCarNum" placeholder="请输入车牌号码" style="width: 180px"></el-input>
				</el-form-item>

				<el-row>
					<el-col :span="8">
						<el-form-item label="房号" prop="memberName">
							<el-input
								v-model="formModel.memberName"
								@focus="getTime()"
								placeholder="房号"
								style="width: 180px"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="联系方式" prop="memberPhone">
							<el-input v-model="formModel.memberPhone" placeholder="请输入联系方式" style="width: 180px"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="授权商户">{{ merchantName }}</el-form-item>
				<!-- <el-form-item label="起始时间" v-if="businessKey.length == 0">
					{{startTime}}
				</el-form-item>-->
				<el-form-item label="起始时间">
					{{ formModel.memberStartTime ? formModel.memberStartTime : "请查询" }}
					<el-button v-if="businessKey.length == 0" @click="getTime()" size="mini" type="primary">查询</el-button>
				</el-form-item>

				<el-form-item label="存放天数" prop="days" v-show="businessKey.length == 0">
					<el-input-number
						:precision="0"
						v-model="formModel.days"
						placeholder="存放天数"
						style="width: 180px"
						:min="1"
					></el-input-number>
				</el-form-item>

				<el-form-item label="结束时间" v-if="businessKey.length == 0">{{ endTime }} {{ memberLeaveTime }}</el-form-item>
				<el-form-item label="结束时间" v-else>{{ formModel.memberEndTime }}</el-form-item>
				<el-form-item label="续费天数" prop="days" v-show="businessKey.length != 0">
					<el-input-number
						:precision="0"
						v-model="formModel.days"
						placeholder="续费天数"
						style="width: 180px"
						:min="0"
					></el-input-number>
				</el-form-item>
			</el-form>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button type="primary" @click="handleSubmit" :loading="submitting">确定赠送</el-button>
		</span>
	</el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingWhiteApi from "@/api/base/parkingWhite";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";

export default {
	components: {

	},

	computed: {
		// 计算属性的 getter
		endTime: function () {
			// `this` 指向 vm 实例
			if (!this.formModel.memberStartTime) {
				return "请查询";
			}
			var date = new Date(this.formModel.memberStartTime.split(" ")[0]);
			var day = this.formModel.days;
			if (!day) {
				return ""
			}
			date.setDate(date.getDate() + day);

			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			if (month < 10) {
				month = "0" + month;
			}
			var data = date.getDate();
			if (data < 10) {
				data = "0" + data;
			}




			return year + "-" + month + "-" + data;
		}
	},

	props: ["title", "businessKey", "memberLeaveTime", "merchantName"],
	data() {
		let Capitalization = (rule, value, callback) => {
			if (!value) {
				value = ""
			}
			this.formModel[rule.field] = value.toUpperCase()
			callback();
		};
		let checkPhone = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
				console.log(reg.test(value));
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("请输入正确的手机号"));
				}
			}
		};
		return {
			myMemberLeaveTime: [{
				required: true,
				message: "请选择离场时间",
				trigger: "blur"
			}],
			formModel: {
				memberCarNum: "鄂D",
				days: 0,
			},
			// endTime:"",
			ruleValidate: {
				memberName: [{
					required: true,
					message: "房号不能为空",
					trigger: "blur"
				},
				{
					validator: Capitalization,
					trigger: "change"
				},


				],
				memberCarNum: [{
					required: true,
					message: "车牌号码不能为空",
					trigger: "blur"
				},
				{
					validator: Capitalization,
					trigger: "change"
				},


				],
				memberPhone: [

					{
						validator: checkPhone,
						trigger: "blur"
					}
				],
			},
			startTime: {},

			showDialog: true,
			loading: false,
			submitting: false,
			typeResult: [],
			companyResult: [],
			props: {
				// 配置项（必选）
				value: "id",
				label: "name",
				children: "children",
			},
		};
	},
	created() {
		var self = this;

		// var date = new Date();
		// var year = date.getFullYear();
		// var month = date.getMonth() + 1;
		// if (month < 10) {
		// 	month = "0" + month;
		// }
		// var data = date.getDate();
		// if (data < 10) {
		// 	data = "0" + data;
		// }

		//this.startTime ="",// year + "-" + month + "-" + data + " " + date.getHours() + ":" + date.getMinutes();



	},
	methods: {
		getTime() {

			var self = this
			if (self.businessKey.length != 0) {
				//编辑状态不调用接口
				return
			}
			self.formModel.memberStartTime = ""
			parkingWhiteApi.getTime(this.formModel.memberCarNum).then((response) => {
				var jsonData = response.data;

				if (jsonData.result) {
					self.formModel.memberStartTime = jsonData.data.time
				} else {

					self.$message.error(jsonData.message + "");
				}
			})
				.catch((error) => {
					self.$message.error(error + "");
				});
		},
		closeDialog() {
			this.$emit("close", false);
		},
		handleSubmit() {
			var self = this;
			if (!this.formModel.memberStartTime) {
				this.$message.error("请先查询入场时间")
				return
			}
			this.formModel.startTime = this.formModel.memberStartTime

			this.$refs["form"].validate((valid) => {

				if (valid) {
					(function () {


						if (self.businessKey.length == 0) {
							self.formModel.endTime = self.endTime + " " + self.memberLeaveTime + ":00"
							return parkingWhiteApi.pcGiveParkingMember(self.formModel);
						} else {
							self.formModel.id = self.businessKey;
							self.formModel.endTime = self.formModel.memberEndTime

							return parkingWhiteApi.editsubmit(self.formModel);
						}


					})().then(function (response) {
						var jsonData = response.data;

						if (jsonData.result) {
							self.$message({
								message: "保存成功!",
								type: "success",
							});

							self.$emit("close", true);
						} else {
							self.$message({
								message: jsonData.message + "",
								type: "warning",
							});

							//self.$emit("close", false);
						}
					});
				}
			});
		},
	},
	mounted: function () {

		var self = this;

		(function () {
			if (self.businessKey.length == 0) {
				return parkingWhiteApi.create();
			} else {
				return parkingWhiteApi.edit(self.businessKey);
			}
		})()
			.then((response) => {
				var jsonData = response.data;

				self.loading = false;

				if (jsonData.result) {
					if (self.businessKey.length == 0) {
						self.formModel = jsonData.data;
					} else {
						var obj = {

							...jsonData.data.data[0]
						}
						obj.days = 0
						self.formModel = obj;

					}

					//self.formModel.days=null;
					if (!self.formModel.memberCarNum) {

						self.formModel.memberCarNum = "鄂D"
					}
				} else {
					self.$message.error(jsonData.message + "");
				}
			})
			.catch((error) => {
				self.$message.error(error + "");
			});
	},
};
</script>
