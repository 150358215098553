import request from '@/utils/request'
import constant from '@/constant'
import Qs from 'qs';

function parkingMemberDetail() {
  return request.post(constant.serverUrl + "/base/parkingMerchant/parkingMemberDetail/" );
}

function getTime(Num) {
  return request.post(constant.serverUrl + "/base/parkingMember/getCarInTime", Qs.stringify({memberCarNum:Num}), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}



function create() {
  return request.get(constant.serverUrl + "/base/parkingMember/create");
}


function edit(id) {
  return request.post(constant.serverUrl + "/base/parkingMember/parkingMemberPageList?id=" + id);
}
function editsubmit(formData) {
  return request.post(constant.serverUrl + "/base/parkingMember/editParkingMember" , Qs.stringify(formData), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
function pageList(formData){
  return request.post(constant.serverUrl + "/base/parkingMember/parkingMemberPageList", formData);
}
 
function remove(id) {
  return request.post(constant.serverUrl + "/base/parkingMember/deleteParkingMember?id=" + id);
}

function view(id) {
  return request.post(constant.serverUrl + "/base/parkingMember/parkingMemberLog?id=" + id);
}

function pcGiveParkingMember(formData) {
  return request.post(constant.serverUrl + "/base/parkingMember/pcGiveParkingMember", Qs.stringify(formData), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

function getQrCodeDetail(formData){
  return request.post(constant.serverUrl + "/base/parkingMerchant/getQrCodeDetail", formData);
}
 
 function updateQrCodeDetail(formData){
   return request.post(constant.serverUrl + "/base/parkingMerchant/updateQrCodeDetail", formData);
 }
  
//edit, add, update, remove, batchRemove,exportXls
export default {
  view,pageList,getQrCodeDetail, updateQrCodeDetail,create,parkingMemberDetail,pcGiveParkingMember,remove,edit,editsubmit,getTime
}