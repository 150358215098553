<template>
	<div>
		<el-breadcrumb separator=">">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>
				<a href="#">停车场管理</a>
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				<a href="/base/parkingWhite/list">车辆白名单管理</a>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<el-divider></el-divider>
		<!--
      要resetFields起作用，必须配置:model和prop
    -->
		<el-dialog title="自主添加会员二维码" :visible.sync="qrVisible" :append-to-body="true" @opened="openQr" width="350px">

			<div id="qrcodeImg" v-if="qrVisible&&jsonData.qrCode"></div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="qrVisible = false">取 消</el-button>
				<el-button type="primary" @click="updateQr">更 新</el-button>
			</span>
		</el-dialog>
		<el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
			<el-form-item label="房号" prop="memberName">
				<el-input type="text" size="mini" v-model="queryModel.memberName"></el-input>
			</el-form-item>
			<el-form-item label="车牌号码" prop="carNum">
				<el-input type="text" size="mini" v-model="queryModel.carNumber"></el-input>
			</el-form-item>

			<el-form-item label="商户名称" v-show="!detail.enableMember" prop="merchantName">
				<el-input type="text" size="mini" v-model="queryModel.merchantName"></el-input>
			</el-form-item>
			<el-form-item label="当前状态" prop="useStatus">
				<el-select size="mini" v-model="queryModel.status">
					<el-option label="全部" value=""></el-option>
					<el-option label="有效" value="0"></el-option>
					<el-option label="无效" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生效时间" prop="startTime">
				<el-date-picker v-model="queryModel.startTime" type="daterange" size="mini" range-separator="至"
					start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>


			<el-form-item label="截止时间" prop="endTime">
				<el-date-picker v-model="queryModel.endTime" type="daterange" size="mini" range-separator="至"
					start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>


			<el-form-item>
				<el-button type="primary" size="mini" icon="ios-search" @click="changePage(1)" :loading="loading">查询
				</el-button>&nbsp;
				<el-button type="info" size="mini" style="margin-left: 8px" @click="handleReset('queryForm')">重置
				</el-button>&nbsp;
			</el-form-item>
		</el-form>
		<el-divider></el-divider>
		<el-row class="button-group">

			<el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd"
				v-show="detail.enableMember">录入车辆</el-button>

			<el-button type="primary" size="small" plain icon="el-icon-download" :loading="downloadLoading"
				@click="downloadXls">导出数据</el-button>

			<el-button type="primary" size="small" plain :loading="qrLoading" @click="queryQR">自主添加会员二维码</el-button>
		</el-row>
		<el-table :data="tableData" style="min-height: 400px" v-loading="loading" stripe @sort-change="sortChange"
			@selection-change="handleSelectionChange" show-summary :summary-method="getSummaries">
			<el-table-column type="index" label="序号" :index="indexMethod" width="80"></el-table-column>
			<el-table-column prop="memberName" label="房号" width="130"></el-table-column>

			<el-table-column prop="memberCarNum" label="车牌号码" width="130"></el-table-column>
			<el-table-column prop="merchantName" label="所属商户" width="180" v-if="!detail.enableMember"></el-table-column>

			<el-table-column prop="statusName" label="当前状态" width="90">

			</el-table-column>

			<el-table-column prop="createTime" label="录入时间" width="180"></el-table-column>

			<el-table-column prop="memberStartTime" label="生效时间" width="180"></el-table-column>
			<el-table-column prop="memberEndTime" label="截止时间" width="180"></el-table-column>

			<el-table-column prop="days" label="存放天数"></el-table-column>

			<el-table-column label="操作" width="250" fixed="right">
				<template slot-scope="{ row }">

					<el-row>
						<el-col>

							<el-link v-if="!queryModel.status" type="warning" @click="handleEdit(row)">编辑</el-link>

							<el-link type="primary" style="margin-left: 20px;" @click="handleView(row)">日志</el-link>

						</el-col>
					</el-row>
				</template>
			</el-table-column>

		</el-table>
		<el-pagination :current-page.sync="pageIndex" :total="totalElements" :page-sizes="pageSizeList"
			@current-change="changePage" @size-change="pageSizeChange" layout="total, sizes, prev, pager, next, jumper">
		</el-pagination>

		<parkingWhite-detail v-if="showModal" :businessKey="businessKey" :merchantName="detail.merchantName"
			:memberLeaveTime="detail.memberLeaveTime" @close="onDetailModalClose"></parkingWhite-detail>


		<el-dialog title="日志查看" :visible.sync="showlogVisible" width="400px" v-loading="loading" append-to-body
			:close-on-click-modal="false" size="tiny">

			<el-steps direction="vertical">
				<el-step status='process' v-for="(item,i) in showlogList" :key="i">
					<div slot="title" style="font-size: 14px;">
						{{item.createTime}}
					</div>
					<div slot="description" style="font-size: 16px;margin-bottom: 10px;">
						{{item.content}}
					</div>
				</el-step>
			</el-steps>

			<ul>

				<li v-if="showlogList.length==0">
					日志为空
				</li>
			</ul>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showlogVisible = false">确定</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import Constant from "@/constant";
	import parkingWhiteApi from "@/api/base/parkingWhite";

	import dataDictionaryApi from "@/api/sys/dataDictionary";
	import companyInfoApi from "@/api/base/companyInfo";
	import SelectTree from "@/components/SelectTree";
	import NProgress from "nprogress"; // progress bar
	import ParkingWhiteDetail from "./parkingWhite-detail";
	import QRCode from "qrcodejs2";

	import "nprogress/nprogress.css"; // progress bar style

	export default {
		name: "baseParkingWhiteList",
		data() {
			var self = this;

			return {
				detail: {},
				showlogVisible: false,
				showlogList: [],
				queryModel: {
					id: "",
					memberName: "",
					carNumber: "",
					merchantName: "",

					startTime: ["", ""],
					endTime: ["", ""],
					status: "",
				},
				qrLoading: false,
				sumDays: 0,
				loading: false,
				tableData: [],
				pageIndex: 1,
				pageSize: 10,
				totalPages: 0,
				totalElements: 0,
				field: "",
				direction: "",
				pageSizeList: [10, 20, 30],
				multipleSelection: [],
				showModal: false,
				modalTitle: "",
				businessKey: "",
				typeResult: [],
				companyResult: [],
				qrVisible: false,
				jsonData: {
					merchantId: "",
					qrCode: ""
				},
				props: {
					// 配置项（必选）
					value: "id",
					label: "name",
					children: "children",
				},
				downloadLoading: false,
			};
		},
		created() {
			var self = this;


		},
		methods: {
			getSummaries(param) {
				var _this = this
				var sz = ['合计天数', '', '', '', '', '', '', _this.sumDays];
				return sz;
			},

			indexMethod(index) {
				return (this.pageIndex - 1) * this.pageSize + (index + 1);
			},
			init() {
				var self = this;

				//self.loading = true;

				parkingWhiteApi
					.parkingMemberDetail()
					.then(function(response) {
						//self.loading = false;

						self.detail = response.data.data;

					})
					.catch((error) => {
						// self.loading = false;
						// self.$message.error(error + "");
					});
			},
			changePage(pageIndex) {
				var self = this;

				self.loading = true;

				self.pageIndex = pageIndex;
				var formData = new FormData();

				formData.append("pageIndex", self.pageIndex);
				formData.append("pageSize", self.pageSize);
				formData.append("id", self.queryModel.id);

				formData.append("memberName", self.queryModel.memberName);
				formData.append("merchantName", self.queryModel.merchantName);


				formData.append("carNumber", self.queryModel.carNumber);
				formData.append("verifyTime", self.queryModel.verifyTime);

				if (self.queryModel.startTime && self.queryModel.startTime[0] != null) {
					formData.append("startTime1", self.queryModel.startTime[0]);
				}

				if (self.queryModel.startTime && self.queryModel.startTime[1] != null) {
					formData.append("startTime2", self.queryModel.startTime[1]);
				}

				if (self.queryModel.endTime && self.queryModel.endTime[0] != null) {
					formData.append("endTime1", self.queryModel.endTime[0]);
				}

				if (self.queryModel.endTime && self.queryModel.endTime[1] != null) {
					formData.append("endTime2", self.queryModel.endTime[1]);
				}

				formData.append("status", self.queryModel.status);

				if (this.field != null) {
					formData.append("field", this.field);
				}

				if (this.direction != null) {
					formData.append("direction", this.direction);
				}

				parkingWhiteApi
					.pageList(formData)
					.then(function(response) {
						self.loading = false;

						var jsonData = response.data.data;

						self.tableData = jsonData.data;
						self.totalPages = jsonData.totalPages;
						self.totalElements = jsonData.recordsTotal;
						self.sumDays = jsonData.sumDays;

					})
					.catch((error) => {
						self.loading = false;
						// self.$message.error(error + "");
					});
			},
			pageSizeChange(pageSize) {
				this.pageSize = pageSize;

				this.$nextTick(() => {
					this.changePage(this.pageIndex);
				});
			},
			sortChange(data) {
				this.field = data.column.field;
				this.direction = data.order;

				this.changePage(this.pageIndex);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleReset(name) {
				this.$refs[name].resetFields();
			},
			handleAdd() {
				this.modalTitle = "录入车辆";
				this.businessKey = "";

				this.merchantName = this.detail.merchantName;
				this.reduce = this.detail.reduce;
				this.showModal = true;
			},
			handleView(record) {
				var _this = this;
				parkingWhiteApi.view(record.id).then(function(response) {
					var jsonData = response.data;

					if (jsonData.result) {
						_this.showlogVisible = true

						_this.showlogList = response.data.data.list

					}
				});
			},
			handleEdit(record) {
				this.modalTitle = "编辑";
				this.businessKey = record.id;
				this.showModal = true;
			},
			handleDelete(record) {
				var self = this;

				self
					.$confirm("是否确认删除?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						parkingWhiteApi.remove(record.id).then(function(response) {
							var jsonData = response.data;

							if (jsonData.result) {
								// var index = self.tableData.indexOf(record);
								// self.tableData.splice(index, 1);
								self.changePage(self.pageIndex);

								self.$message({
									type: "success",
									message: "删除成功!",
								});
							}
						});
					});
			},
			handleBatchDelete() {
				var self = this;

				var idList = this.multipleSelection.map((record) => {
					return record.id;
				});

				this.$confirm("是否确认删除选中项？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					parkingWhiteApi.batchRemove(idList).then(function(response) {
						var jsonData = response.data;

						if (jsonData.result) {
							self.changePage(self.pageIndex);

							self.$message({
								type: "success",
								message: "删除成功!",
							});
						}
					});
				});
			},
			onDetailModalClose(refreshed) {
				//保存成功后回调
				this.showModal = false;

				if (refreshed) {
					this.changePage(this.pageIndex);
				}
			},
			updateQr() {
				this.jsonData.qrCode = ""
				this.qrLoading = true;
				var self = this;
				parkingWhiteApi.updateQrCodeDetail().then(function(response) {
					self.qrLoading = false;
					var jsonData = response.data;

					if (jsonData.result) {
						self.jsonData.qrCode = jsonData.data.qrCode;
						self.$nextTick(() => {
							self.openQr();
							self.$message.success("更新成功");

						})


					} else {
						self.$message.error(jsonData.message);
					}
				});
			},
			queryQR() {
				this.qrLoading = true;
				var self = this;
				parkingWhiteApi.getQrCodeDetail().then(function(response) {
					self.qrLoading = false;
					var jsonData = response.data;
					//https://xpgj.xiaoxinda.com/xpgj/prod/parking/#/
					self.loading = false;
					if (jsonData.result) {
						//下载有错误信息提示的报表
						self.qrVisible = true;
						self.jsonData = jsonData.data;

					} else {
						self.$message.error(jsonData.message);
					}
				});
			},
			openQr() {

				new QRCode("qrcodeImg", {
					id: "qrcodeImgItem",
					text: "https://xpgj.xiaoxinda.com/xpgj/prod/parking/#/car/input?companyId=" + this.jsonData
						.merchantId + "&qrCode=" + this.jsonData.qrCode,

					width: 300,
					height: 300,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
				});

			},
			downloadXls() {
				//导出
				var self = this;
				self.downloadLoading = true;
				self.loading = true;
				self.loadingText = "数据导出中，请稍后...";

				var formData = new FormData();

				formData.append("pageIndex", self.pageIndex);
				formData.append("pageSize", self.pageSize);

				formData.append("id", self.queryModel.id);
				formData.append("merchantName", self.queryModel.merchantName);
				formData.append("type", self.queryModel.type);

				if (self.queryModel.companyId != null) {
					formData.append("companyId", self.queryModel.companyId);
				}
				formData.append("carNum", self.queryModel.carNum);
				formData.append("verifyTime", self.queryModel.verifyTime);

				if (self.queryModel.verifyTimeRange[0] != null) {
					formData.append("verifyStartTime", self.queryModel.verifyTimeRange[0]);
				}

				if (self.queryModel.verifyTimeRange[1] != null) {
					formData.append("verifyEndTime", self.queryModel.verifyTimeRange[1]);
				}

				formData.append("useStatus", self.queryModel.useStatus);

				if (this.field != null) {
					formData.append("field", this.field);
				}

				if (this.direction != null) {
					formData.append("direction", this.direction);
				}

				parkingWhiteApi.exportXls(formData).then(function(response) {
					var jsonData = response.data;
					self.downloadLoading = false;
					self.loading = false;
					if (jsonData.result) {
						//下载有错误信息提示的报表
						self.$message({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
							duration: 60000,
							type: 'success'
						});
					} else {
						self.$message.error(jsonData.message);
					}
				});
			},
		},
		mounted: function() {
			this.init();
			//this.changePage(1);
		},
		components: {

			"parkingWhite-detail": ParkingWhiteDetail,
		},
	};
</script>
<style lang="scss" scoped>
	.el-breadcrumb {
		margin: 10px;
		line-height: 20px;
	}

	.el-divider {
		margin: 5px 0;
	}

	.demo-form-inline {
		margin-left: 10px;
		text-align: left;
	}

	.button-group {
		margin-left: 10px;
		text-align: left;
	}
</style>
